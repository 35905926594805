<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1200"
    @click:outside="clickOutside"
  >
    <template v-slot:activator="{ on, attrs }">
      <p id="grItemForm" v-bind="attrs" v-on="on" @click="openItemForm"></p>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5" style="margin: auto">DETAIL BARANG</span>
      </v-card-title>
      <v-form
        v-if="form != null"
        ref="formGrDetailItem"
        :disabled="loading || detail.status != 0"
        lazy-validation
        @submit.prevent="saveForm"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="9" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Nama Barang
                </p>

                <v-text-field
                  v-if="act == 'update' && form.product != null"
                  :value="`(${form.product.code}) ${form.product.name}`"
                  dense
                  disabled
                  outlined
                  style="font-size: 12px; height: 45px"
                />

                <v-autocomplete
                  v-if="act == 'add'"
                  v-model="form.product"
                  :items="dropdown.fpbItem"
                  :rules="rules.productRules"
                  clearable
                  outlined
                  item-text="product.name"
                  item-value="id"
                  hide-details
                  hide-no-data
                  return-object
                  label=""
                  style="margin: 0"
                  @change="productWatcher"
                  dense
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>
                          ({{ data.item.product.code }}) {{ data.item.product.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Harga: {{ data.item.budget_price_per_unit }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="d-flex flex-column"
                v-if="act == 'add' && form.product != null"
              >
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Kode Product
                </p>

                <v-text-field
                  :value="form.product.product.code"
                  dense
                  outlined
                  readonly
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col v-else cols="12" md="3"></v-col>
              <v-col
                cols="12"
                class="d-flex flex-column"
                v-if="act == 'add' && form.product != null"
              >
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Vendor name
                </p>

                <v-text-field
                  :value="detail.received_from_vendor_name"
                  dense
                  outlined
                  readonly
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <!-- <v-col v-if="act == 'add' && form.product != null" cols="12" md="6"></v-col> -->
              <!-- <v-col
                cols="12"
                md="6"
                class="d-flex flex-column"
                v-if="act == 'add' && form.product != null"
              >
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Payreq
                </p>

                <v-text-field
                  :value="form.product.document_no"
                  dense
                  outlined
                  readonly
                  style="font-size: 12px; height: 45px"
                />
              </v-col> -->
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Qty Dibayar
                </p>

                <v-text-field
                  v-model="form.qty_request"
                  dense
                  outlined
                  type="number"
                  min="0"
                  disabled
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <!-- <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Qty kembali
                </p>

                <v-text-field
                  v-model="form.qty_return"
                  dense
                  outlined
                  type="number"
                  min="0"
                  disabled
                  style="font-size: 12px; height: 45px"
                />
              </v-col> -->
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Qty dapat diproses
                </p>

                <v-text-field
                  :value="form.qty_request - form.processed"
                  dense
                  outlined
                  type="number"
                  min="0"
                  disabled
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Qty Diterima
                </p>

                <v-text-field
                  v-model="form.qty_done"
                  :rules="rules.qtyRules"
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                />
                <!-- :max="form.qty_request - form.processed" -->
              </v-col>
              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Satuan
                </p>

                <v-autocomplete
                  v-model="form.uom"
                  :items="dropdown.unit"
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  :readonly="act == 'add'"
                  :clearable="act != 'add'"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Keterangan
                </p>
                <v-textarea
                  v-model="form.description"
                  dense
                  outlined
                  rows="3"
                  style="font-size: 12px"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div style="padding: 0 25px 20px; width: 100%">
            <v-btn
              style="width: 100%"
              color="blue darken-1"
              class="white--text"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              SIMPAN
            </v-btn>
            <v-btn
              style="width: 100%; margin-top: 15px"
              color="pink"
              class="white--text"
              type="button"
              @click="closeDialog"
              :loading="loading"
            >
              BATAL
            </v-btn>
          </div>
          <!-- <v-btn color="blue darken-1" text type="submit"> Save </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  name: 'goodsReceiptItemForm',
  props: ['detail', 'itemDetail', 'act', 'proc'],
  data() {
    return {
      dialog: false,
      form: null,
      dropdown: {
        unit: [],
        fpbItem: [],
        payreqItem: []
      },
      rules: {
        qtyRules: [],
        productRules: []
      },
      loading: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    dropdownUnit() {
      console.log('hereee>>.', this.proc)

      axios
        .get(`${this.proc}master/unit/drop_down?keyword=`)
        .then((res) => {
          this.dropdown.unit = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    openItemForm() {
      let processedQty = 0
      console.log('hallo world', this.itemDetail)
      if (this.act == 'add') {
        this.form = {
          act: this.act,
          // qty_demand: 0,
          qty_request: 0,
          qty_done: 0,
          // qty_return: 0,
          product: null,
          uom: null,
          description: '',
          processed: processedQty
          // payment_request: null
        }
        // this.getPayreqItemDropdown()
        this.getFpbItemDropdown()
      } else if (this.act == 'update') {
        processedQty = this.calulateTotalQty(this.itemDetail.prd_id)
        this.form = {
          act: this.act,
          // qty_demand: this.itemDetail.qty_demand,
          product: this.itemDetail.product,
          uom: {
            id: this.itemDetail.uom_id,
            name: this.itemDetail.uom_name
          },
          // qty_request:
          //   this.itemDetail.purchase_request_detail != null
          //     ? this.itemDetail.purchase_request_detail.qty - this.itemDetail.purchase_request_detail.qty_left
          //     : 0,
          qty_request:
            this.itemDetail.purchase_request_detail != null
              ? this.itemDetail.purchase_request_detail.qty_paid
              : 0,
          processed: processedQty - this.itemDetail.qty_done,
          qty_done: this.itemDetail.qty_done,
          // qty_return: this.itemDetail.qty_return,
          description: this.itemDetail.description
        }
      }
      this.dropdownUnit()
    },
    // clear() {
    //   this.$emit('loadPage')
    // },
    clickOutside() {
      //   this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
      setTimeout(() => {
        this.form = null
      }, 200)
    },
    saveForm() {
      this.rules.qtyRules = [
        (v) => !!v || 'Wajib diisi',
        v => v > 0 || 'Tidak boleh kosong',
        v => v <= this.form.qty_request - this.form.processed || `Melebihi batas, ( Qty tersedia = ${this.form.qty_request - this.form.processed})`
      ]
      this.rules.productRules = [(v) => !!v || 'Wajib diisi']
      const self = this
      setTimeout(function () {
        if (self.$refs.formGrDetailItem.validate()) {
          self.createNewForm()
        }
      })
      // setTimeout(() => {
      //   this.dialog = false
      // }, 200)
    },
    createNewForm() {
      console.log('this.form', this.form)
      const newForm = {
        act: this.form.act,
        goods_receipt_id: this.detail.id,
        // qty_request: Number(this.form.qty_request),
        qty_done: Number(this.form.qty_done),
        description: this.form.description
      }
      if (this.form.act == 'add') {
        Object.assign(newForm, {
          // payreq_no: res.data.data.document_no,
          // payment_request_id: this.dropdown.payreqItem[1].payment_request_id,
          prd_id: this.form.product.clear_id,
          product_id: this.form.product.product.id,
          product_code: this.form.product.product.code,
          product_name: this.form.product.product.name,
          uom_id: this.form.uom.id,
          uom_name: this.form.uom.name,
          // qty_demand: this.form.product.qty_demand,
          // qty_request: this.form.product.qty_request,
          // qty_return: res.data.data.payment_request_detail[i].uom_id,
          qty_done: Number(this.form.qty_done),
          description: this.form.description
        })
      }
      if (this.form.act == 'update') {
        Object.assign(newForm, {
          id: this.itemDetail.id,
          prd_id: this.itemDetail.prd_id
        })
      }
      console.log(newForm)
      this.submitDetailItem(newForm)
    },
    async submitDetailItem(form) {
      this.loading = true
      await axios
        .post(`${this.proc}gr/detail/save`, form)
        .then((res) => {
          console.log(res.data)
          this.loading = false
          if (res.data.status_code == '00') {
            this.dialog = false
            setTimeout(() => {
              this.$emit('loadPage')
            }, 200)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    getFpbItemDropdown() {
      const url = `${this.proc}fpb/detail/${this.detail.purchase_request.id}`
      // url = url + '&order_by=prd.id&order_type=ASC'
      axios
        .get(url)
        .then((res) => {
          if (res.data.status_code == '00') {
            if (res.data.data.purchase_request_detail != null) {
              this.dropdown.fpbItem = res.data.data.purchase_request_detail
              console.log('fpb detail item>>>', this.dropdown.fpbItem)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // getPayreqItemDropdown() {
    //   const url = `${this.proc}payreq/dropdown_detail?purchase_request_id=${this.detail.purchase_request.id}&vendor_name=${this.detail.received_from_vendor_name}`
    //   // url = url + '&order_by=prd.id&order_type=ASC'
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       if (res.data.status_code == '00') {
    //         this.dropdown.payreqItem = res.data.data
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // },
    // calculateTotalPrice() {
    //   let total = 0
    //   const qty = Number(
    //     this.form.qty_request != null ? this.form.qty_request : 0
    //   )
    //   const price = Number(
    //     this.form.price_request != null ? this.form.price_request : 0
    //   )
    //   const discount_amount = Number(
    //     this.form.discount_amount != null ? this.form.discount_amount : 0
    //   )

    //   const discount_precent = Number(
    //     this.form.discount_percent != null ? this.form.discount_percent : 0
    //   )

    //   const tax = Number(this.form.tax != null ? this.form.tax : 0)

    //   const calc_before_discount = qty * price + tax
    //   // let calc_after_discount = 0

    //   if (discount_amount != 0 && discount_amount != null) {
    //     total = calc_before_discount - discount_amount
    //   } else if (discount_precent != 0 && discount_amount != null) {
    //     total =
    //       calc_before_discount - (calc_before_discount * discount_precent) / 100
    //   } else {
    //     total = calc_before_discount
    //   }

    //   return total
    // },
    productWatcher(item) {
      console.log(item)
      if (item != null) {
        this.form.processed = this.calulateTotalQty(item.clear_id)
        // this.form.payment_request = {
        //   id: item.payment_request_id,
        //   document_no: item.document_no,
        //   vendor_name: item.vendor_name
        // }
        // this.form.qty_request = item.qty - item.qty_left
        this.form.qty_request = item.qty_paid
        this.form.uom = {
          id: item.uom_id,
          name: item.uom
        }
      }
    },
    calulateTotalQty(xPrd_id) {
      let total = 0
      const xArrGrItem = this.detail.goods_receipt_detail.filter(({ prd_id }) => prd_id == xPrd_id)
      total = xArrGrItem.reduce((accum, item) => accum + item.qty_done, 0)

      return total
    }
  }
}
</script>
